import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class CreateApiKey extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
            eventName : '',
            ip : '',
            secretkey : '',
			generateButton: true,
			apiKeygenerated: false,
			apikey : '',
			disableState : true,
            disableState1 : false,
			userAiAdded : false,
            apiKeyState : true,

		}

	}

	componentDidMount() {
        const apiPath = this.props.app.apiPath;
		const aId = this.props.selectedApiKeyId;
        Fetcher.get(apiPath + '/api/obd/getApiKey/' + aId)
			.then(res => {
				this.setState({
                    secretkey : res.secretKey,
                    apikey : res.apikey,
                    ip : res.ip
				})
                console.log("Api Key : "+res.apikey);
                console.log("Api Key : "+this.state.apikey);
			})
			.catch(err => { console.log('Error in fetching Api Key data', err) });
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}


	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	// generateApiKey = () => {
		
	// 	console.log("Here");

	// 	if(this.state.secretkey === ""){
	// 		Notify.notify("Please Enter Secret key to generate api key");
	// 		return;
	// 	}


	// 	const characters = this.state.secretkey;
    //     let apiSecretKey = '';

    //     for (let i = 0; i < 32; i++) {
    //          const randomIndex = Math.floor(Math.random() * characters.length);
    //          apiSecretKey += characters.charAt(randomIndex);
    //        }

    //     console.log("secret key : "+apiSecretKey);
	// 	if(apiSecretKey!==''){
	// 		console.log("secret key : "+apiSecretKey);
	// 	    this.state.apikey  = apiSecretKey;
	// 		this.setState({
	// 			generateButton : false,
	// 			apiKeygenerated : true,
	// 			disableState : true
	// 		})
	// 	}
	// 	console.log("b : "+this.state.generateButton);
	// 	return;

	// }

	generateApiKey = () => {
        // Use the secretKey as a seed for the random number generator
		const secretKey = this.state.secretkey;
		const apiKeyLength = 64;
        const seed = Array.from(secretKey).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const random = (seed) => () => (seed = (seed * 9301 + 49297) % 233280) / 233280.0;

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$%&*'+secretKey;
    
        const generateKey1 = (length, rand) => {
            let key = '';
            for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(rand() * characters.length);
            key += characters.charAt(randomIndex);
        }
        return key;
    };

    const rand = random(seed);
    const apiKey = generateKey1(apiKeyLength, rand);
	console.log("Api Key : "+apiKey);
	if(apiKey!==''){
		    this.state.apikey  = apiKey;
			this.setState({
				generateButton : false,
				apiKeygenerated : true,
				disableState1 : true
			})
		}
    return;
    }

	handleAddApiKey = (event) => {
		event.preventDefault();
		const data = {
			"ip": this.state.ip,
			"apikey": this.state.apikey,
            "userId" : Number(this.state.userId),
            "secretKey":this.state.secretkey,
            "id": this.props.selectedApiKeyId
		};

		console.log(JSON.stringify(data));

		return Fetcher.post(this.props.app.apiPath + '/api/obd/apikey/edit', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
					
				let userAiAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					userAiAdded = true;
				}
				this.setState({
					userAiAdded: userAiAdded
				})
			})
			.catch(err => {
				console.log('Error in Adding webhooks', err);
				Notify.notify('Error in Adding webhooks');
			});
	}

	render() {
		if (this.state.userAiAdded === true) {
			return <Redirect to='/user-api-key-list' />
		}
		

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddApiKey(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update Api Key</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Label>Secret Key</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="secretkey" type="text" placeholder="Secret key" disabled = {this.state.disableState1} onChange={e => this.setvalue( e )} value={this.state.secretkey} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Label>IP</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="ip" type="text" placeholder="IP" onChange={e => this.setvalue( e )} value={this.state.ip} />
										</Form.Group>
									</Col>
									
									<Col sm={3}>
										<Form.Label>Api key</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="apikey" type="text" placeholder="Api key" disabled = {this.state.disableState} onChange={e => this.setvalue( e )} value={this.state.apikey} />
										</Form.Group>
									</Col>
									{this.state.generateButton &&
									<Col sm={2}>
										<Button onClick={this.generateApiKey} variant="primary">Generate Key</Button>
									</Col>
	                                }
				
								</Row>
								<Button variant="primary" type="submit">Update</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default CreateApiKey;