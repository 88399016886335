import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class AddWebhooks extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			webhookAdded: false,
			mobile: '',
            eventList : [],
            webhookname : '',
            webhookurl : '',
            eventName : ''
		}

	}

	componentDidMount() {
        const apiPath = this.state.apiPath;
		const userId = this.state.userId;
		Fetcher.get(apiPath + '/api/obd/webhooks/events')
			.then(res => {
				this.setState({
					eventList : res
				})
			})
			.catch(err => { console.log('Error in fetching Events', err) });
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	handleAddWebhooks = (event) => {
		event.preventDefault();
		const data = {
			"webhookName": this.state.webhookname,
			"url": this.state.webhookurl,
            "event" : this.state.eventName,
            "userId" : Number(this.state.userId)
		};

		return Fetcher.post(this.props.app.apiPath + '/api/obd/addWebHooks', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
					
				let webhookAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					webhookAdded = true;
				}
				this.setState({
					webhookAdded: webhookAdded
				})
			})
			.catch(err => {
				console.log('Error in Adding webhooks', err);
				Notify.notify('Error in Adding webhooks');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	render() {
		if (this.state.webhookAdded === true) {
			return <Redirect to='/webhook-list' />
		}
		let submitButtonlabel = 'Add Webhook';

        const eventList = cloneDeep(this.state.eventList);
		let eventDropdown = '';
		if (!isEmpty(eventList) && (!('error' in eventList))) {
			eventDropdown = Array.isArray(eventList) ? eventList.map((obj, index) => {
				return <option value={obj.eventName} key={`eventid${index}`} >{obj.eventName}</option>
			}) : []

		} else {
			eventDropdown = <option value="" >No Event found</option>
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddWebhooks(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Add Webhook</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="webhookname" type="text" placeholder="Webhook Name" onChange={e => this.setvalue(e)} value={this.state.webhookname} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="webhookurl" type="text" placeholder="Webhook Url" onChange={e => this.setvalue(e)} value={this.state.webhookurl} autoComplete="new-password"/>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="eventName" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Events</option>
												{eventDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddWebhooks;