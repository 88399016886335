import React from 'react';
import AddSMSCampaign from './AddSMSCampaign';
import ListSMSCampaign from './ListSMSCampaign';
import SMSCampaignReport from './SMSCampaignReport';
import { Route, Switch } from "react-router-dom";
const Campaign = (props) => {
	const urlPrefix = props.app.urlPrefix;
	return (

		<div className="">
			<Switch>
				<Route path={`${urlPrefix}/add-sms-campaign`} ><AddSMSCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/sms-campaign-summary`} ><ListSMSCampaign app={props.app} /></Route>

				<Route path={`${urlPrefix}/sms-campaign-report/:campaignId`} render={(routeprops) => (
					<SMSCampaignReport campaignId={routeprops.match.params.campaignId} app={props.app} {...routeprops} />)
				} />
			</Switch>
		</div>

	);
}

export default Campaign;
