import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notify = {
	notify: (message) => 
	{
		toast.info(message, {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 2000,
			draggable: false
		});
	}
};


export { Notify };